<template>
  <v-hover
    v-slot="{ hover }"
  >
    <v-card
      :elevation="hover ? 6 : 2"
      rounded
      height="80"
      class="my-4"
    >
      <v-row no-gutters>
        <v-col md="1">
          <v-card
            class="rounded-r-0"
            flat
            height="80"
            :color="notification && notification.content && !notification.content.includes('Error while processing Document') ? 'success' : 'error'"
          >
            <v-card-text
              class="text-center"
            >
              <v-icon
                v-if="notification && notification.content && !notification.content.includes('Error while processing Document')"
                color="white"
                size="30"
                class="centered-icon"
              >
                mdi-check-circle-outline
              </v-icon>
              <v-icon
                v-if="notification && notification.content && notification.content.includes('Error while processing Document')"
                color="white"
                size="30"
                class="centered-icon"
              >
                mdi-alert-octagon-outline
              </v-icon>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col md="11">
          <v-card
            dark
            flat
            height="80"
            class="rounded-l-0"
          >
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  style="position: absolute; top: 2px; right: 8px;"
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-dots-horizontal
                </v-icon>
              </template>
              <v-list>
                <v-list-item
                  @click.stop="$emit('delete-notification', notification)"
                >
                  <v-icon
                    class="mt-1 mr-2"
                  >
                    mdi-delete-outline
                  </v-icon>
                  <v-list-item-title>delete notification</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="notification && notification.content && !notification.content.includes('Error while processing Document')"
                  @click="$emit('open-document', notification.relevant_object)"
                >
                  <v-icon
                    class="mt-1 mr-2"
                  >
                    mdi-file-eye
                  </v-icon>
                  <v-list-item-title>open document</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="notification && notification.content && notification.content.includes('Error while processing Document')"
                  @click.stop="$emit('open-info-dialog')"
                >
                  <v-icon
                    class="mt-1 mr-2"
                  >
                    mdi-information-variant
                  </v-icon>
                  <v-list-item-title>show more</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-card-text
              class="black--text mt-1 mr-4"
            >
              <span>{{ notification && notification.content ? notification.content : notification.content }}</span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-hover>
</template>

<script>

export default {
  components: {},
  props: {
    notification: {
      type: Object,
      default: null
    }
  },
  data: () => ({}),
  methods: {}
}
</script>

<style scoped>
.centered-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
